export class channelPerformanceFilterData {
	static getDateType() {
		return [
			{
				name: 'Touchpoint Date',
				value: 3,
			},
			{
				name: 'Created Date',
				value: 1,
			},
			{
				name: 'Closed Date',
				value: 2,
			},
		];
	}

	static getConnectors() {
		return [
			{
				name: 'All',
				value: 'all',
			},

			{
				name: 'Facebook',
				value: 'facebook',
			},
			{
				name: 'LinkedIn',
				value: 'linkedin',
			},
			{
				name: 'Instagram',
				value: 'instagram',
			},
			{
				name: 'Youtube',
				value: 'youtube',
			},
		];
	}
	static getAttributionModel() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 2,
			},
			{
				name: 'First Touch',
				value: 3,
			},
			{
				name: 'Last Interaction',
				value: 4,
			},
			{
				name: 'Last Non-Direct',
				value: 5,
			},
			{
				name: 'Linear',
				value: 1,
			},
			{
				name: 'U-Shaped',
				value: 6,
			},
			{
				name: 'W-Shaped',
				value: 7,
			},
			{
				name: 'Full Path Model',
				value: 8,
			},
		];
	}
	static getAttributionModelforPCT() {
		return [
			{
				name: 'First Touch',
				value: 3,
			},
			{
				name: 'Last Interaction',
				value: 4,
			},

			{
				name: 'Linear',
				value: 1,
			},
		];
	}
	static getDimensionParameter() {
		return [
			{
				name: 'Channel',
				value: 1,
			},
			{
				name: 'Source',
				value: 2,
			},
		];
	}
	static getOperatorList() {
		return [
			{
				name: 'Containing',
				value: 1,
			},
			{
				name: 'Exactly matching',
				value: 2,
			},
			{
				name: 'Starts with',
				value: 3,
			},
			{
				name: 'Ends with',
				value: 4,
			},
		];
	}

	static getChannelPerformanceMedium = () => [
		{
			name: 'Channel',
			value: 1,
		},
		// {
		// 	name: 'Source',
		// 	value: 2,
		// },
	];
}
