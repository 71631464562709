import React from 'react';
import {
	Modal,
	Box,
	Typography,
	Button,
	IconButton,
	Backdrop,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/styles';
interface CustomModalProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	children?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
	open,
	onClose,
	title,
	children,
}) => {
	const theme: any = useTheme();
	return (
		<>
			<div
				style={
					{
						// backgroundColor: theme.palette.background.alt,
						// backgroundColor: "#161B22",
						// border: theme?.palette?.borderColorAbm?.borderColor,
						// border: "2px solid  #30363D",
					}
				}
			>
				<Modal
					open={open}
					onClose={onClose}
					aria-labelledby='modal-title'
					// onClose={() => {}}
					// hideBackdrop
					// BackdropComponent={(props) => (
					// 	<Backdrop {...props} sx={{ backdropFilter: 'blur(8px)' }} />
					// )}
					style={{ outline: 'none' }}
					disableAutoFocus
				>
					<Box
						sx={{
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '70%',
							// bgcolor: "background.paper",
							boxShadow: 24,
							borderRadius: 2,
							position: 'relative', // Needed for absolute positioning of the close button
						}}
					>
						<IconButton
							onClick={(event) => {
								event.stopPropagation();
								onClose();
							}}
							className='close-icon ignore-click-outside'
							sx={{
								position: 'absolute',
								top: -4,
								right: 0,
								// color: 'grey.600', // Adjust color as needed
							}}
						>
							<CancelIcon />
						</IconButton>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							{title && (
								<Typography id='modal-title' variant='h6' component='h2' mb={2}>
									{title}
								</Typography>
							)}
						</div>
						<Box>{children}</Box>
					</Box>
				</Modal>
			</div>
		</>
	);
};

export default CustomModal;
