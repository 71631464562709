export const buildFilterCondition = (
	globalfilter: {
		para?: string;
		opera?: string;
		value?: string;
		relational?: string;
		filterCondition?: string;
	} = {},
	setFilterCondition: React.Dispatch<React.SetStateAction<string>> = () => {},
	setErrors: React.Dispatch<
		React.SetStateAction<{
			parameter: string;
			operation: string;
			value: string;
		}>
	> = () => {}
) => {
	const {
		para: parameter,
		opera: operation,
		value,
		relational: relationalOperator,
		filterCondition,
	} = globalfilter;

	// Check for empty fields and set error messages
	setErrors({
		parameter: !parameter ? 'Required' : '',
		operation: !operation ? 'Required' : '',
		value: !value ? 'Required' : '',
	});

	// Exit early if any of the fields are empty
	if (!parameter || !operation || !value) {
		return null; // Return null or an appropriate fallback if there's an error
	}

	// Define operation mappings
	const operationsMap: Record<string, string> = {
		'Exactly matching': '=',
		Containing: `LIKE '%${value}%'`,
		'Starts with': `LIKE '${value}%'`,
		'Ends with': `LIKE '%${value}'`,
		'Does not Contain': `NOT LIKE '%${value}%'`,
		'Not Equal': '!=',
	};

	// Get the corresponding SQL operation or fallback to custom operation
	const operationText = operationsMap[operation] || operation;

	// Build the filter condition
	const condition = operationText.includes('LIKE')
		? `${parameter} ${operationText}`
		: `${parameter} ${operationText} '${value}'`;

	// Check if the filter condition already has an unclosed bracket
	const unclosedBrackets = /\([^\)]*$/;

	// Create a new condition by either appending the relational operator or wrapping it with parentheses
	let newCondition = '';
	if (unclosedBrackets.test(filterCondition ?? '')) {
		// Set the filter condition without adding relational operator
		newCondition = filterCondition
			? `${filterCondition} ${condition}`
			: condition;
	} else {
		// If there's a relational operator, wrap the condition with the relational operator
		newCondition = filterCondition
			? `${filterCondition} ${relationalOperator ?? ''} (${condition})`
			: `(${condition})`; // If no existing condition, just start with the condition in parentheses
	}

	// Set the filter condition
	setFilterCondition(newCondition);

	// Clear the selections
	setErrors({ parameter: '', operation: '', value: '' }); // Clear errors after successful submission

	// Return the newly built condition
	return `${relationalOperator ?? ''}(${condition})`; // Return the condition wrapped with relational operator
};

export // Function to validate the filter condition and check for common issues like invalid operator combinations,
// unclosed brackets, and empty brackets
const validateFilterCondition = (filterCondition: string, toaster: any) => {
	// Regular expression to match invalid logical operator combinations:
	// "ANDAND", "OROR", "AND OR", "OR AND", "ANDOR", "ORAND"
	const regex = /\b(AND|OR)\s*\1\b|\b(ANDOR|ORAND|AND\sOR|OR\sAND)\b/;

	// Regular expression to check for unclosed brackets (e.g., "(Channel LIKE '%paid%'")
	const unclosedBrackets = /\([^\)]*$/;

	// Regular expression to check for empty brackets (e.g., "()")
	const emptyBrackets = /\(\s*\)/;

	// Validate filter condition for invalid operator combinations
	if (regex.test(filterCondition)) {
		// Show error for invalid operator combinations (e.g., AND AND, OR OR, etc.)
		toaster.addToast({
			message:
				'Invalid filter condition: Invalid logical operator combinations (AND AND / OR OR / AND OR / OR AND / ANDOR / ORAND) are not allowed.',
			timeout: 2000,
			type: 'error',
		});
		return; // Prevent further processing if invalid operator combinations are found
	}

	// Validate if there are unclosed brackets
	if (unclosedBrackets.test(filterCondition)) {
		// Show error if there are unclosed brackets (e.g., "(Channel LIKE '%paid%")
		toaster.addToast({
			message:
				'Invalid filter condition: Please ensure all brackets are properly closed.',
			timeout: 2000,
			type: 'error',
		});
		return; // Prevent further processing if there are unclosed brackets
	}

	// Validate if there are empty brackets
	if (emptyBrackets.test(filterCondition)) {
		// Show error if there are empty brackets (e.g., "()")
		toaster.addToast({
			message: 'Invalid filter condition: Empty brackets are not allowed.',
			timeout: 2000,
			type: 'error',
		});
		return; // Prevent further processing if empty brackets are found
	}

	// If no validation errors, return true to indicate the condition is valid
	return true;
};
