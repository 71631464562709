import React, { useState } from 'react';
import './UpgradePopup.scss';
import CustomModal from './customModal/CustomModal';
import { LightPopupRrightImage, PopupRrightImage } from '../DemoVideo/index';
import {
	Crown,
	DiagramUp,
	supportHand,
	aiStar,
	supportHandLight,
	aiLight,
	DiagramLight,
} from '../DemoVideo/index';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import useApiService from '../../services/api.service';
import DemoEndpoints from './DemovideoEndpoints';
import { useLocation } from 'react-router-dom';
import useToast from '../../components/Toast/hooks/useToast';
interface UpgradePopupProps {
	openPopFunc: (value: boolean) => void; // Function that accepts a boolean argument
}
const UpgradePopup: React.FC<UpgradePopupProps> = (props) => {
	const { openPopFunc } = props;

	const [open, setOpen] = useState(true);
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	const theme: any = useTheme();
	const APIService = useApiService();
	const location = useLocation();
	const toaster: any = useToast();
	const { pathname, search } = location;
	const sendEmailFunc = async (data: any) => {
		setIsButtonClicked(true);
		// console.log('button clickced');
		const reqData = {
			// sender_email: 'info@diggrowth.com',
			// recipient_email: 'shami.kumar@diggrowth.com',
			// subject: 'subject',
			// email_body: 'email_body',
		};
		const request: any = {
			headers: {
				'Report-Name': pathname,
			},
			reqBody: { ...reqData },
		};
		try {
			const res = await APIService.post(DemoEndpoints.send_mail(request), true);
			// console.log('kkkk', res);

			if (res?.status === 200) {
				toaster.addToast({
					message:
						'A dedicated product manager has been assigned to you and will reach out to you via email shortly',
					timeout: 4000,
					type: 'success',
				});
			}
			openPopFunc(false);
			setIsButtonClicked(false);
		} catch (error: any) {
			console.log('send email api error -->', error);
			setIsButtonClicked(false);
		}
	};

	const resetState = () => {
		openPopFunc(false);
		setOpen(false);
	};

	return (
		<>
			<div>
				{/* <CustomModal open={open} onClose={() => setOpen(false)} title=''> */}
				<CustomModal open={open} onClose={resetState} title=''>
					<div
						className='upgrade-popup'
						data-ignore-click-outside // 👈 Add this attribute
						style={{
							background: theme.palette.mode === 'light' ? 'white' : '#161B22',
						}}
					>
						{/* Left Section */}
						<div className='left-section'>
							<div>
								<img
									src={Crown}
									alt='crown-image'
									className='crown-image'
									loading='eager'
								/>
							</div>
							<Typography
								variant='h3'
								style={{ margin: '7px 0px', fontSize: '22px' }}
							>
								Upgrade to see your data
							</Typography>
							<Typography
								variant='h5'
								className='subtitle'
								style={{
									color: theme.palette.mode === 'light' ? '#BCBCBD' : '#BCBCBD',
									fontSize: '14px',
								}}
							>
								Unlock our best dashboards
							</Typography>
							<Typography
								variant='body2'
								className='subtitle'
								style={{
									color: theme.palette.mode === 'light' ? '#BCBCBD' : '#BCBCBD',
									fontSize: '14px',
								}}
							>
								Here’s what you’ll get with the full upgrade:
							</Typography>
							<div className='features-list'>
								<div className='feature-item'>
									<img
										src={
											theme.palette.mode === 'light' ? DiagramLight : DiagramUp
										}
										alt='diagram up'
										loading='eager'
									/>
									<div>
										<Typography variant='h5' style={{ fontSize: '16px' }}>
											Real-Time Analytics
										</Typography>
										<Typography
											variant='body2'
											style={{
												color:
													theme.palette.mode === 'light'
														? '#BCBCBD'
														: '#BCBCBD',
												fontSize: '14px',
											}}
										>
											Make faster, smarter decisions with actionable insights
											powered by your actual business data, not placeholders.
										</Typography>
									</div>
								</div>
								<div className='feature-item'>
									<img
										src={theme.palette.mode === 'light' ? aiLight : aiStar}
										alt='ai star'
										loading='eager'
									/>
									<div>
										<Typography variant='h5' style={{ fontSize: '16px' }}>
											Full AI-Powered Functionalities
										</Typography>
										<Typography
											variant='body2'
											style={{
												color:
													theme.palette.mode === 'light'
														? '#BCBCBD'
														: '#BCBCBD',
												fontSize: '14px',
											}}
										>
											Leverage cutting-edge AI to automate processes, uncover
											actionable insights, and drive smarter decisions.
										</Typography>
									</div>
								</div>
								<div className='feature-item'>
									<img
										src={
											theme.palette.mode === 'light'
												? supportHandLight
												: supportHand
										}
										alt='support hand'
										loading='eager'
									/>
									<div>
										<Typography variant='h5' style={{ fontSize: '16px' }}>
											Expert Support on Demand
										</Typography>
										<Typography
											variant='body2'
											style={{
												color:
													theme.palette.mode === 'light'
														? '#BCBCBD'
														: '#BCBCBD',
												fontSize: '14px',
											}}
										>
											Gain priority access to our dedicated support team to help
											you get the most out of DiGGrowth.
										</Typography>
									</div>
								</div>
							</div>
							<Button
								// variant='contained'
								// color='primary'
								onClick={sendEmailFunc}
								disabled={isButtonClicked}
								className='upgrade-button'
								style={{
									background: isButtonClicked
										? 'grey'
										: theme.palette.mode === 'light'
										? '#9149FF'
										: 'white',
									color: theme.palette.mode === 'light' ? 'white' : '#161B22',
									textTransform: 'none',
									fontSize: '18px',
								}}
							>
								Upgrade to DiGGrowth Pro!
							</Button>
						</div>

						{/* Right Section */}
						<div className='right-section'>
							<img
								src={
									theme.palette.mode === 'light'
										? LightPopupRrightImage
										: PopupRrightImage
								}
								alt='Upgrade Preview'
								className='upgrade-image'
								loading='eager'
							/>
						</div>
					</div>
				</CustomModal>
			</div>
		</>
	);
};

export default UpgradePopup;
