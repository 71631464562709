const DemoEndpoints = {
	watch_video(data) {
		return {
			url: 'kcauth/watchVideo',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	checkDemoData(data) {
		return {
			url: 'kcauth/checkDemoData',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	checkRegStatus(data) {
		return {
			url: 'kcauth/checkRegStatus ',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	registerUpdate(data) {
		return {
			url: 'kcauth/registration   ',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	send_mail(data) {
		return {
			url: 'kcauth/send_email   ',
			data: data.reqBody,
			headers: data.headers,
		};
	},
};
export default DemoEndpoints;
