import React from 'react';

const deleteIconPurple = (props) => {
	return (
		<div style={{ paddingTop: props.newFilter ? '0.2rem' : '' }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width={props.newFilter ? '1.5rem' : '20.453'}
				height={props.newFilter ? '1.5rem' : '27.65'}
				viewBox='0 0 20.453 27.65'
			>
				<g id='Group_3223' data-name='Group 3223' transform='translate(0 0)'>
					<path
						id='Path_1279'
						data-name='Path 1279'
						d='M290.037,265.492a.983.983,0,0,0,.983-.983v-6.1a.983.983,0,0,0-1.967,0v6.1A.983.983,0,0,0,290.037,265.492Z'
						transform='translate(-282.17 -244.841)'
						fill={props.newFilter ? 'red' : props.fill || '#9149ff'}
					/>
					<path
						id='Path_1280'
						data-name='Path 1280'
						d='M372.487,265.492a.983.983,0,0,0,.983-.983v-6.1a.983.983,0,1,0-1.967,0v6.1A.983.983,0,0,0,372.487,265.492Z'
						transform='translate(-360.097 -244.841)'
						fill={props.newFilter ? 'red' : props.fill || '#9149ff'}
					/>
					<path
						id='Path_1281'
						data-name='Path 1281'
						d='M188.625,148.757V131.29H168.73v17.466a4.517,4.517,0,0,0,4.517,4.517h10.861a4.516,4.516,0,0,0,4.517-4.517Zm-1.966,0a2.553,2.553,0,0,1-2.55,2.55H173.247a2.553,2.553,0,0,1-2.55-2.55v-15.5h15.962v15.5Z'
						transform='translate(-168.447 -125.623)'
						fill={props.newFilter ? 'red' : props.fill || '#9149ff'}
					/>
					<path
						id='Path_1282'
						data-name='Path 1282'
						d='M164.564,31.539H183.05a.983.983,0,0,0,0-1.967h-5.511A.979.979,0,0,0,176.757,28h-5.9a.983.983,0,0,0-.983.983.973.973,0,0,0,.2.59h-5.511a.983.983,0,0,0,0,1.967Z'
						transform='translate(-163.58 -27.998)'
						fill={props.newFilter ? 'red' : props.fill || '#9149ff'}
					/>
				</g>
			</svg>
		</div>
	);
};

export default deleteIconPurple;
