export class FilterData {
	static getDateType() {
		return [
			{
				name: 'Touchpoint Date',
				value: 'touch_point',
			},
			{
				name: 'Created Date',
				value: 'created_date_utc',
			},
			{
				name: 'Closed Date',
				value: 'close_date',
			},
		];
	}

	static getTopConversionDateType() {
		return [
			{
				name: 'Touchpoint Date',
				value: 3,
			},
			{
				name: 'Created Date',
				value: 1,
			},
			{
				name: 'Closed Date',
				value: 2,
			},
		];
	}

	static getConnectors() {
		return [
			{
				name: 'All',
				value: 1,
			},

			{
				name: 'Facebook',
				value: 2,
			},
			{
				name: 'LinkedIn',
				value: 3,
			},
			{
				name: 'Instagram',
				value: 4,
			},
			{
				name: 'Youtube',
				value: 5,
			},
		];
	}
	static getAttributionModelForCohort() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 2,
			},
			{
				name: 'First Touch',
				value: 3,
			},
			{
				name: 'Last Interaction',
				value: 4,
			},
			{
				name: 'Last Non-Direct',
				value: 5,
			},
			{
				name: 'Linear',
				value: 1,
			},
			{
				name: 'U-Shaped',
				value: 6,
			},
			{
				name: 'W-Shaped',
				value: 7,
			},
			{
				name: 'Full Path Model',
				value: 8,
			},
			// {
			//   name: 'Data-driven attribution',
			//   value: 'markov',
			// },
		];
	}
	static getAttributionModel() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 2,
			},
			{
				name: 'First Touch',
				value: 3,
			},
			{
				name: 'Last Interaction',
				value: 4,
			},
			{
				name: 'Last Non-Direct',
				value: 5,
			},
			{
				name: 'Linear',
				value: 1,
			},
			{
				name: 'U-Shaped',
				value: 6,
			},
			{
				name: 'W-Shaped',
				value: 7,
			},
			{
				name: 'Full Path Model',
				value: 8,
			},
			// {
			//   name: 'Data-driven attribution',
			//   value: 'markov',
			// },
		];
	}

	static getAttributionModelForSmd() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_create',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_touch',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non_direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path',
			},
		];
	}

	static getOperatorListforCohort() {
		return [
			{
				name: 'Containing',
				value: 1,
			},
			{
				name: 'Exactly matching',
				value: 2,
			},
			{
				name: 'Starts with',
				value: 3,
			},
			{
				name: 'Ends with',
				value: 4,
			},
		];
	}

	static getCohortDimensionParameter() {
		return [
			{
				name: 'Channel',
				value: 1,
			},
			{
				name: 'Source',
				value: 2,
			},
			{
				name: 'Campaign',
				value: 4,
			},
			{
				name: 'Ad Group',
				value: 5,
			},
			{
				name: 'Keyword',
				value: 3,
			},
		];
	}

	static getAttributionModelforPCT() {
		return [
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},

			{
				name: 'Linear',
				value: 'linear_model',
			},
		];
	}

	static getAttributionModelForSEO() {
		return [
			{
				name: 'Linear',
				// value: 'linear_model',
				value: 1,
			},
		];
	}

	static getAttributionModelComparison() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_creation_touch',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non-direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path_model',
			},
			{
				name: 'Data-driven attribution 1',
				value: 'markov',
			},
			{
				name: 'Data-driven attribution 2',
				value: 'shapley',
			},
			// {
			//   name: 'Data-driven attribution 3',
			//   value: 'occurrence',
			// },
		];
	}

	static getPMTAttributionModel() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_creation_touch',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non-direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path_model',
			},
			// {
			//   name: 'Data-driven attribution',
			//   value: 'markov',
			// },
		];
	}

	static getDimensionParameter() {
		return [
			{
				name: 'Channel',
				value: 'channel',
			},
			{
				name: 'Source',
				value: 'source',
			},
			{
				name: 'Campaign',
				value: 'campaign',
			},
			{
				name: 'Ad Group',
				value: 'ad_group',
			},
			{
				name: 'Keyword',
				value: 'keyword',
			},
		];
	}

	static getDimensionParameterforPCt() {
		return [
			{
				name: 'Channel',
				value: 'channel',
			},
			{
				name: 'Source',
				value: 'source',
			},
			{
				name: 'Campaign',
				value: 'campaign',
			},
		];
	}

	static getOperatorList() {
		return [
			{
				name: 'Containing',
				value: 'contains',
			},
			{
				name: 'Exactly matching',
				value: 'equals',
			},
			{
				name: 'Starts with',
				value: 'starts_with',
			},
			{
				name: 'Ends with',
				value: 'ends_with',
			},
		];
	}

	static getOperatorListForTopConversion() {
		return [
			{
				name: 'Containing',
				value: 1,
			},
			{
				name: 'Exactly matching',
				value: 2,
			},
			{
				name: 'Starts with',
				value: 3,
			},
			{
				name: 'Ends with',
				value: 4,
			},
		];
	}

	static getMediumLabels = () => [
		{
			name: 'Channel',
			value: 'channel',
		},
		{
			name: 'Source',
			value: 'source',
		},
		{
			name: 'Campaign Name',
			value: 'campaign',
		},
		{
			name: 'AdGroup',
			value: 'AdGroup',
			disabled: true,
		},
		{
			name: 'Keyword',
			value: 'keyword',
			disabled: true,
		},
	];

	static getChannelPerformanceMedium = () => [
		{
			name: 'Channel',
			value: 'channel',
		},
		{
			name: 'Source',
			value: 'source',
		},
	];

	static getTopConversionMedium = () => [
		{
			name: 'Channel',
			// value: 'channel',
			value: 1,
		},
	];

	static hubspotEmailFilter = () => [
		{
			name: 'demoMail.com',
			value: 'demo',
		},
		{
			name: 'test@here.com',
			value: 'test',
		},
	];

	static hubspotNameFilter = () => [
		{
			name: 'Demo',
			value: 'demo',
		},
		{
			name: 'Tester',
			value: 'test',
		},
	];
}

export const ignoreOrderCompare = (a: any = [], b: any = []) => {
	// if (a.length !== b.length) return false;
	const elements = new Set([...a, ...b]);
	for (const x of elements) {
		const count1 = a?.filter((e: any) => e === x).length;
		const count2 = b?.filter((e: any) => e === x).length;

		if (count1 !== count2) return false;
	}
	return true;
};
