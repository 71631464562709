// import { useTheme } from '@mui/styles';
// import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom';
// import { Typography } from '@mui/material';
// import { Crown,} from '../index';
// import CloseIcon from '@mui/icons-material/Close';
// const Marquee = () => {
//     const location = useLocation();
//     const { pathname, search } = location;
//     const theme: any = useTheme();
//     console.log("checking path", pathname)

//     const [visible, setVisible] = useState(true);

//     if (!visible) return null;
//     return (
//         <>
//         <div>
//             <div
//                 style={{
//                     background: theme.palette.mode === 'light' ? '#9149FF' : 'white',
//                     color: theme.palette.mode === 'light' ? 'white' : '#161B22',
//                     textTransform: 'none',
//                     textAlign: "center",
//                     display: "flex",
//                     justifyContent:"center",
//                     alignItems: "center"
//                 }}
//             >
//                 <Typography
//                     variant='h5'
//                     className='digg_pro'
//                     style={{fontWeight: 500}}
//                 >
//                     This report is generated using sample data. To view insights based on your own data, upgrade to </Typography><span className=' digg_Color'>DiGGrowth Pro!</span>
//                     <div >
//                             <img src={Crown} alt='crown-image'  className='crown-image-marque'/>
//                     </div>
//             </div>
//             <CloseIcon/>
//             </div>
//             <style>
//                 {
//                     `
//             .digg_Color{
//             font-family: Poppins;
//             font-weight: 600;
//             font-size: 18px;
//             line-height: 27px;
//             letter-spacing: 0%;
//             text-decoration: underline;
//             text-decoration-style: solid;
//             text-decoration-offset: Auto;
//             text-decoration-thickness: Auto;
//             text-decoration-skip-ink: auto;
//               color: #9149FF;
//             margin-left: 8px;
//             }

//             .crown-image-marque{
//             width: 27px;
//             height: 27px;
//             margin-left: 10px;
//             }
//             `
//                 }
//             </style>
//         </>
//     )
// }

// export default Marquee;

import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { Crown } from '../index'; // Ensure the correct import of the Crown image
import { useTheme } from '@mui/material/styles';
import UpgradePopup from '../UpgradePopup';

const Marquee = () => {
	const theme = useTheme();
	const [visible, setVisible] = useState(true);
	const [openPopValue, setOpenPopValue] = useState(false);

	if (!visible) return null;

	const openPopFunc = (clickValue: boolean) => {
		setOpenPopValue(clickValue);
	};
	return (
		<>
			<div className='banner-container'>
				<div
					className='banner-content'
					style={{
						background: theme.palette.mode === 'light' ? 'black' : 'white',
						color: theme.palette.mode === 'light' ? 'white' : '#161B22',
					}}
				>
					<Typography
						variant='h6'
						className='digg_pro'
						style={{ fontWeight: 500, fontSize: '0.8rem' }}
					>
						This report is generated using sample data. To view insights based
						on your own data, upgrade to
					</Typography>
					<span className='digg_Color' onClick={() => openPopFunc(true)}>
						DiGGrowth Pro!
						<img
							src={Crown}
							alt='crown-image'
							className='crown-image-marque'
							// onClick={() => openPopFunc(true)}
							style={{ cursor: 'pointer' }}
						/>
					</span>
					{/* <button className='close-btn' onClick={() => setVisible(false)}>
						<CloseIcon />
					</button> */}
				</div>
			</div>
			{openPopValue && <UpgradePopup openPopFunc={openPopFunc} />}
			<style>
				{`
          .banner-container {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .banner-content {
            display: flex;
            align-items: baseline;
            justify-content: center;
            text-align: center;
            width: 100%;
            padding: 0px 20px;
            position: relative;
          }

          .digg_Color {
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 0.8rem;
            line-height: 27px;
            text-decoration: underline;
            color: #9149FF;
            margin-left: 8px;
            cursor: pointer;
			display:flex;
			justify-content:center;
          }

          .crown-image-marque {
            width: 23px;
            height: 23px;
            margin-left: 10px;
			margin-top: 0px;
          }

          .close-btn {
            position: absolute;
            top: 10px;
            right: 15px;
            background: none;
            border: none;
            cursor: pointer;
            color: inherit;
          }

          .close-btn svg {
            width: 24px;
            height: 24px;
          }
			.digg_pro{
			 font-family: Poppins, sans-serif;
			}
        `}
			</style>
		</>
	);
};

export default Marquee;
