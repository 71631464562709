import { useEffect, useState } from 'react';
import ReactDateTimePicker from '../DateTimePicker/ReactDateTimePicker';
import headerService from '../../services/header.service';
import useLocalStorage from '../../utils/localStorage';
import FilterModal from '../FilterButtonModal/FilterModal';
import { useTheme } from '@mui/styles';
import {
	InsightsBreadCrumbs,
	InsightsData,
} from '../../containers/Reports/Dashboard/InsightsData';
import { Grid, Typography } from '@mui/material';
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs';
import InsightsButton from '../Insights/InsightsButton';
import BookMarkIcon from '../Bookmark/BookmarkButton';
import { customDashboardBreadcum } from './CommonHeaderData';
import ShareIcon from '@mui/icons-material/Share';
import ShareReports from '../../containers/ShareReports/ShareReports';
import { useSelector } from 'react-redux';
import useApiService from '../../services/api.service';
import moment from 'moment';
import SwitchToDiggML from '../../containers/SwitchToDigg/SwitchToDiggML';
import HeaderFilterModal from '../FilterButtonModal/HeaderFilterModal';
import appEndpoints from '../../App.endpoints';
import useToast from '../Toast/hooks/useToast';
import { DateTimeFormatter } from '../../utils/DateFormatter';
import { fetchFilters } from '../../utils/fetchFilters';

const CommonHeader = () => {
	const filterData = useLocalStorage.getItem('filters');
	const APIService = useApiService();
	const toaster: any = useToast();

	// -----------------------hooks----------------------------------
	let [dateRange, setDateRange]: any = useState(InsightsData.getDefaultRange());
	const [showPercentageChange, setShowPercentageChange]: any = useState(
		useLocalStorage.getItem('date_comparison')
	);
	const [selectedFilters, setSelectedFilters]: any = useState(
		InsightsData.getDefaultCMOFilters()
	); // setData from fetch api here half prblm sorted
	// --------------------------------------------------------------
	const [open, setOpen] = useState(false);
	const [showFilter, setShowFilter] = useState(true);
	const [nameofSegments, setNameofSegments] = useState([]);
	// --------------------------Service---------------------------------
	const theme: any = useTheme();
	const apiService = useApiService();
	// -------------------------------------------------------------------

	// ------------------------- Redux Management ---------------------

	const cmoDashboardStore: any = useSelector((state) => state);
	const csvDataPayload: any = useSelector((state: any) => state?.cmoCsvPayload);

	// ----------------------------------------------------------------
	// const [shareReportsRequestLatest, setShareReportsRequestLatest] = useState(
	const shareReportsRequestLatest = {
		// linking_type: 0,
		// format_key: 0,
		// email_list: [""],
		// dashboard_id: 1,
		// report_name: "paid_campaign_tracker"
		// email_list: [
		// 	""
		// ],
		// "dashboard_id": 1,
		// "report_name": "cmo_dashboard",
		closed_won: {
			api: `${process.env.REACT_APP_API_URL}marketingnode/marketing_reports/closedWon`,
			payload: {
				...(csvDataPayload?.closed_won || ''),
			},
		},
		performance_report: {
			api: `${process.env.REACT_APP_API_URL}performancereport/performanceReport`,
			payload: {
				...(csvDataPayload?.performance_report || ''),
			},
		},
		pipeline: {
			api: `${process.env.REACT_APP_API_URL}marketingnode/marketing_reports/pipeline`,
			payload: {
				...(csvDataPayload?.pipeline || ''),
			},
		},
	};

	const onChangeDateFilter = (dateRange: any) => {
		//need to check when does redux gets strore as it is getting updated on line 83 -----------------------------
		const data = {
			type: 'dateRange',
			data: dateRange,
		};
		headerService.emit(data);
		setShowFilter(false);
		setDateRange(dateRange);
	};
	const onCompareChange = (event: any) => {
		const data = {
			type: 'onCompareChange',
			data: useLocalStorage.getItem('date_comparison'),
		};
		headerService.emit(data);
		setShowPercentageChange(useLocalStorage.getItem('date_comparison'));
	};

	// useEffect(() => {
	// const dates: any = useLocalStorage.getItem('dateRange');
	// if (dates) {
	// 	dateRange = dates;
	// 	setDateRange({ ...dateRange });
	// }
	// let data = filterData?.insights;
	// setSelectedFilters(data);
	// }, []);

	// useEffect(() => {
	// 	if (showFilter) {
	// 		const updatedFilters = { ...cmoDashboardStore?.global_filter_data };
	// 		setSelectedFilters(updatedFilters);

	// 		setTimeout(() => {
	// 			fetchsavedFilter(updatedFilters);
	// 		}, 1000);
	// 	}
	// }, [cmoDashboardStore?.global_filter_data]);

	// const fetchsavedFilter = async (prevFilters: any) => {
	// 	await fetchFilters('CMO Dashboard', '/insights/cmo/dashboard', apiService)
	// 		.then((res: any) => {
	// 			setSelectedFilters({
	// 				...prevFilters, // Keep previously set filters
	// 				segmentsName: res?.data?.segmentsName, // Add new segment name
	// 			});
	// 		});
	// };

	useEffect(() => {
		if (showFilter) {
			setSelectedFilters({ ...cmoDashboardStore?.global_filter_data });
		}
	}, [cmoDashboardStore?.global_filter_data]);

	const onResetFilter = () => ({
		date_type: '',
		attribution_model: 1,
		relational_operator: 1,
		excludes: [],
		conditions: [],
		segment_ids: [],
		segmentsName: [],
		start_date: InsightsData.getDefaultFilters().start_date,
		end_date: InsightsData.getDefaultFilters().end_date,
	});

	const saveFilters = async (data: any) => {
		let filtersData = data.conditions;
		delete data.segmentsLength;
		delete data.isReload;
		delete data.conditions;
		delete data.excludes;
		const req = {
			...data,
			filters: { condition: filtersData },
			bookmark_name: 'CMO Dashboard',
			page_url: location.pathname,
		};
		return await APIService.post(appEndpoints.saveUpdateGlobalFilter(req), true)
			.then((data: any) => {
				return data;
			})
			.catch((err: any) => {
				toaster.addToast({
					message: 'Failed to save filters',
					timeout: 2000,
					type: 'error',
				});
			});
	};

	const reorderSegments = (segments: { label: string; value: number }[]) => {
		// Separate zero-value segment(s) from others
		const zeroValueSegments = segments.filter((segment) => segment.value === 0);
		const nonZeroSegments = segments.filter((segment) => segment.value !== 0);

		// If no zero-value segment exists, return the array as is
		return zeroValueSegments.length > 0
			? [...zeroValueSegments, ...nonZeroSegments]
			: segments;
	};

	const getSegmentsName = (names: any) => {
		// const sortedSegments: any = reorderSegments(names);
		setNameofSegments(names);
	};

	const onSubmitFilter = async (filterDataOnSubmit: any) => {
		// if (filterDataOnSubmit?.segment_id.length <= 1) {
		setShowFilter(false);
		const updatedConditions = filterDataOnSubmit?.conditions?.map(
			(condition: any) => {
				delete condition.relational_operator;
				return condition;
			}
		);
		selectedFilters.pre_start_date =
			useLocalStorage.getItem('dateRanges')?.pre_start_date;
		selectedFilters.pre_end_date =
			useLocalStorage.getItem('dateRanges')?.pre_end_date;

		selectedFilters.start_date = dateRange?.start_date
			? DateTimeFormatter.getDateInFormat(dateRange?.start_date)
			: DateTimeFormatter.getPastValueFromToday(6);
		selectedFilters.end_date = dateRange?.end_date
			? DateTimeFormatter.getDateInFormat(dateRange?.end_date)
			: DateTimeFormatter.getCurrentDate();
		selectedFilters.date_type = filterDataOnSubmit?.date_type;
		selectedFilters.attribution_model = filterDataOnSubmit?.attribution_model;
		selectedFilters.conditions = updatedConditions || [];
		selectedFilters.segment_ids = filterDataOnSubmit?.segment_ids || [];
		selectedFilters.relational_operator =
			filterDataOnSubmit?.relational_operator || null;
		selectedFilters.segmentsName = filterDataOnSubmit?.segmentsName;
		useLocalStorage.setItem(
			'segmentsLength',
			filterDataOnSubmit?.segment_ids?.length
		);
		setSelectedFilters({ ...selectedFilters });
		// setSelectedFilters(selectedFilters);
		const updatedFilters = { condition: updatedConditions };
		const data = {
			type: 'submitFilter',
			data: {
				...selectedFilters,
				conditions: updatedFilters?.condition,
				segmentsLength: filterDataOnSubmit?.segment_ids?.length,
				segmentsName: filterDataOnSubmit?.segmentsName,
			},
		};
		useLocalStorage.setItem('filterDataforStackSegment', data.data);
		await headerService.emit(data);
		//
		await saveFilters(selectedFilters);
	};

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				padding: '10px',
				alignItems: 'center',
			}}
			className='helo'
		>
			<div style={{ width: '35em' }}>
				<Typography
					variant='h5'
					component='span'
					color={theme.palette.text.primary}
				>
					{location.pathname == '/custom/dashboard' ? (
						<span style={{ fontSize: '20px' }}>Custom Dashboard</span>
					) : (
						'CMO Dashboard'
					)}
				</Typography>
				<BreadCrumbs
					breadCrumbsList={
						location.pathname == '/custom/dashboard'
							? null
							: InsightsBreadCrumbs
					}
				/>
			</div>
			<div
				style={{
					float: 'right',
					display: 'flex',
					alignSelf: 'center',
					justifyContent: 'right',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<SwitchToDiggML
					left={location.pathname == '/custom/dashboard' ? '0rem' : ''}
				/>
				<Grid
					// item
					display='flex'
					justifyContent='start'
					style={{
						left: location.pathname == '/custom/dashboard' ? '0' : '2.5rem',
						position: 'relative',
					}}
				>
					<ShareReports
						name={'CMO'}
						id={1}
						filters={selectedFilters}
						shareReportsRequest={{
							...cmoDashboardStore?.cmo_request_body?.filter_data,
							start_date: moment(dateRange.start_date).format('YYYY-MM-DD'),
							end_date: moment(dateRange.end_date).format('YYYY-MM-DD'),
							dimension: cmoDashboardStore?.cmo_dimension_value,
							matrices: cmoDashboardStore?.cmo_metric_value,
						}}
						downloadPDFClassName={'dashboard'}
						report_name={'cmo_dashboard'}
						shareReportsRequestLatest={shareReportsRequestLatest}
					/>
				</Grid>
				<Grid
					// item
					display='flex'
					justifyContent='start'
					style={{
						left: location.pathname == '/custom/dashboard' ? '0' : '1.5rem',
						position: 'relative',
					}}
				>
					<InsightsButton />
				</Grid>
				{location.pathname != '/custom/dashboard' ? (
					<BookMarkIcon
						payload={cmoDashboardStore?.cmo_bookmark_request_body || []}
						bookmarkName='CMO Dashboard Report'
					/>
				) : null}
				{location.pathname == '/insights/cmo/dashboard' ||
				location.pathname == '/custom/dashboard' ? (
					<HeaderFilterModal
						filters={InsightsData.getFiltersforCMO()}
						onSubmit={onSubmitFilter}
						selectedFilters={selectedFilters}
						onReset={() => onResetFilter()}
						showSegments
						fromCMO={true}
						segmentsName={getSegmentsName}
					/>
				) : // <FilterModal
				// 	filters={InsightsData.getFiltersforCMO()}
				// 	onReset={() => onResetFilter()}
				// 	selectedFilters={selectedFilters}
				// 	showSegments
				// 	onSubmit={onSubmitFilter}
				// />
				null}
				{location.pathname == '/insights/cmo/dashboard' ||
				location.pathname == '/custom/dashboard' ? (
					<ReactDateTimePicker
						onChange={onChangeDateFilter}
						onCompareChange={(evt: any) => onCompareChange(evt)}
						defaultMonths={1}
						start_date={dateRange?.start_date}
						end_date={dateRange?.end_date}
						pre_start_date={dateRange?.pre_start_date}
						pre_end_date={dateRange?.pre_end_date}
						showComparison={true}
						showPercentageChange={showPercentageChange}
						minLastYear={2}
						isMultipleRanges
					/>
				) : null}
			</div>
		</div>
	);
};

export default CommonHeader;
