import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { ToastContextProvider } from './components/Toast/Toast.context';
import toastReducer from './components/Toast/reducers/reducer';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './AppContext/App.context';
import appReducer from './AppContext/reducers/reducer';
import * as serviceWorker from './serviceWorkerRegistration';
import { ThemeApp } from './containers/Settings/GlobalSettings/GlobalSettingsTabs/ThemeContext';
import { DateRangeProvider } from './utils/DateRangeContext';
import { SidebarProvider } from './utils/SideBarContext';

ReactDOM.render(
	<Suspense fallback={''}>
		<BrowserRouter>
			<DateRangeProvider>
				<SidebarProvider>
					<ThemeApp>
						<AppContextProvider reducer={appReducer}>
							<ToastContextProvider reducer={toastReducer}>
								<App />
							</ToastContextProvider>
						</AppContextProvider>
					</ThemeApp>
				</SidebarProvider>
			</DateRangeProvider>
		</BrowserRouter>
	</Suspense>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register({
	onSuccess: (serviceWorkerRegistration) => {},
	onUpdate: (serviceWorkerRegistration) => {
		const registrationWaiting = serviceWorkerRegistration.waiting;
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
			registrationWaiting.addEventListener('statechange', (e: any) => {
				// eslint-disable-next-line no-use-before-define
				if (e.target.state === 'activated') {
					window.location.reload();
				}
			});
		}
	},
});
