export const AppUtilityFunctions = {
	getRedirectUrl() {
		let redirectUrl = window.location.origin + '/login';
		return redirectUrl;
	},
	getDataTableColumns(tableData: any, sort?: Boolean, center?: Boolean) {
		if (tableData?.length > 0) {
			const transformedData = Object.values(tableData).map((key: any) => ({
				selector: key,
				name: key.replace(/_/g, ' '),
				sortable: sort ? true : false,
				center: center ? true : false,
			}));
			return transformedData;
		}
	},
	convertToSnakeCase(text: string) {
		return text.toLowerCase().replace(/ /g, '_');
	},
	capitalizeFirstOnly(str: string) {
		if (!str) return ''; // Handle empty strings
		return str.charAt(0).toUpperCase() + str.slice(1);
	},

	calculateTimeDifferenceInSeconds(date1: any, date2: any) {
		const timeDifferenceInMilliseconds = date1 - date2;
		const timeDifferenceInSeconds = Math.floor(
			timeDifferenceInMilliseconds / 1000
		);
		return timeDifferenceInSeconds;
	},

	truncateText(text: any, maxLength: any) {
		if (typeof text !== 'string' || typeof maxLength !== 'number') {
			return '';
		}
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + '...';
		}
		return text;
	},
	convertPercentageStringToNumber(percentageString: string) {
		const numberString = percentageString
			? percentageString.replace('%', '')
			: '0';
		const number = parseFloat(numberString);
		return isNaN(number) ? 0 : number;
	},

	getCurrentDateTimeWithAddedMinutes(minutesToAdd: any) {
		const currentDateTime = new Date(); // Get the current date and time
		const newDateTime = new Date(
			currentDateTime.getTime() + minutesToAdd * 60 * 1000
		); // Add minutes
		return newDateTime;
	},
	removeSpacesAndAddUnderscores(inputString: string) {
		const str = inputString;
		const replStr = ' ';
		const newStr = '_';
		const updatedStr = str.replace(replStr, newStr);
		return updatedStr;
	},

	parseDateStringToDate(dateString: any) {
		// Parse the date string into a Date object
		return new Date(dateString);
	},

	customizeResponseforMultiSelect(list: any) {
		let temp: any = [];
		list.map((data: any, index: any) => {
			temp.push({
				label: data.name,
				value: data.value,
			});
		});
		return temp;
	},

	addOveralltoList(list: any) {
		let temp: any = [...list];
		temp.unshift({
			label: 'Overall',
			value: 0,
		});
		return temp;
	},
	removeUnderScoreAddSpace(status: any) {
		return status
			.replace(/_/g, ' ')
			.replace(/\b\w/g, (char: any) => char.toUpperCase());
	},

	addOveralltoListforNormalDropdown(list: any) {
		let temp: any = [...list];
		temp.unshift({
			name: 'Overall',
			value: 0,
		});
		return temp;
	},
	extractDate(datetimeString: string) {
		const date = new Date(datetimeString);
		if (isNaN(date.getTime())) {
			throw new Error('Invalid datetime string');
		}
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	},
	isFreemiumUser() {
		const demoData = localStorage.getItem('demoDataFreemium');
		if (!demoData) return false;

		try {
			const parsedData = JSON.parse(demoData);
			return parsedData[0].category_id !== 1;
		} catch (error) {
			console.error('Error parsing demoDataFreemium:', error);
			return false;
		}
	},
};
