import React, { createContext, useContext, useEffect, useState } from 'react';
import { DateTimeFormatter } from './DateFormatter';

const DateTypeContext = createContext();

export const useDateType = () => useContext(DateTypeContext);

export const DateTypeProvider = ({ children }) => {
	const [dateType, setDateType] = useState(2);
	const [currentTabs, setCurrentTabs] = useState(0);
	const [filterApplied, setFilterApplied] = useState(false);
	return (
		<DateTypeContext.Provider
			value={{
				dateType,
				setDateType,
				filterApplied,
				setFilterApplied,
				currentTabs,
				setCurrentTabs,
			}}
		>
			{children}
		</DateTypeContext.Provider>
	);
};

export default DateTypeContext;
